<template>
  <div>
    <el-tabs v-model="activeName">
      <!-- 波动检查 -->
      <el-tab-pane
        :label="$t('config_group.fluctuationCheck')"
        name="1"
      >
        <simple-form
          ref="form"
          v-model="formModel"
          label-width="80px"
          :form-field="formField"
          :grid="{xs: 12, sm: 12, md: 12}"
          :view-flag="viewFlag"
        >
          <template slot="kpi">
            <el-button
              v-if="!viewFlag"
              type="primary"
              @click="handleAddClick(1)"
            >
              {{ $t('operation.create') }}
            </el-button>
          </template>
        </simple-form>
        <simple-table
          style="height:360px;margin:0 16px;"
          :table-column="tableColumn"
          :table-data.sync="tableData1"
          :with-pagination="false"
        >
          <template
            slot="businessArea"
            slot-scope="scope"
          >
            {{ $getDictLabel({type: 'mt_kpi_business_area', value: scope.row.kpi.businessArea}) }}
          </template>
          <template
            slot="operation"
            slot-scope="scope"
          >
            <el-button
              circle
              icon="el-icon-edit"
              :disabled="viewFlag"
              :title="$t('operation.edit')"
              @click="handleEditClick(scope.row, scope.$index, 1)"
            />
            <el-button
              circle
              icon="el-icon-delete"
              :disabled="viewFlag"
              :title="$t('operation.delete')"
              @click="handleDeleteClick(scope.$index, 1)"
            />
          </template>
        </simple-table>
      </el-tab-pane>

      <!-- 阈值检查 -->
      <el-tab-pane
        :label="$t('config_group.thresholdCheck')"
        name="2"
      >
        <simple-form
          ref="form"
          v-model="formModel"
          label-width="80px"
          :form-field="formField"
          :grid="{xs: 12, sm: 12, md: 12}"
          :view-flag="viewFlag"
        >
          <template slot="kpi">
            <el-button
              v-if="!viewFlag"
              type="primary"
              @click="handleAddClick(2)"
            >
              {{ $t('operation.create') }}
            </el-button>
          </template>
        </simple-form>
        <simple-table
          style="height:360px;margin:0 16px;"
          :table-column="tableColumn"
          :table-data="tableData2"
          :with-pagination="false"
        >
          <template
            slot="businessArea"
            slot-scope="scope"
          >
            {{ $getDictLabel({type: 'mt_kpi_business_area', value: scope.row.kpi.businessArea}) }}
          </template>
          <template
            slot="operation"
            slot-scope="scope"
          >
            <el-button
              circle
              icon="el-icon-edit"
              :disabled="viewFlag"
              :title="$t('operation.edit')"
              @click="handleEditClick(scope.row, scope.$index, 2)"
            />
            <el-button
              circle
              :disabled="viewFlag"
              icon="el-icon-delete"
              :title="$t('operation.delete')"
              @click="handleDeleteClick(scope.$index, 2)"
            />
          </template>
        </simple-table>
      </el-tab-pane>

      <!-- 逻辑检查 -->
      <el-tab-pane
        :label="$t('config_group.logicalCheck')"
        name="3"
      >
        <simple-form
          ref="form"
          v-model="formModel"
          label-width="80px"
          :form-field="formField"
          :grid="{xs: 12, sm: 12, md: 12}"
          :view-flag="viewFlag"
        >
          <template slot="kpi">
            <el-button
              v-if="!viewFlag"
              type="primary"
              @click="handleAddClick(3)"
            >
              {{ $t('operation.create') }}
            </el-button>
          </template>
        </simple-form>
        <simple-table
          style="height:360px;margin:0 16px;"
          :table-column="tableColumn"
          :table-data="tableData3"
          :with-pagination="false"
        >
          <template
            slot="businessArea"
            slot-scope="scope"
          >
            {{ $getDictLabel({type: 'mt_kpi_business_area', value: scope.row.kpi.businessArea}) }}
          </template>
          <template
            slot="checkRule"
            slot-scope="scope"
          >
            {{ $getDictLabel({type: 'mt_group_kpi_type_check_rule', value: scope.row.checkRule}) }}
          </template>
          <template
            slot="operation"
            slot-scope="scope"
          >
            <el-button
              circle
              icon="el-icon-edit"
              :disabled="viewFlag"
              :title="$t('operation.edit')"
              @click="handleEditClick(scope.row, scope.$index, 3)"
            />
            <el-button
              circle
              :disabled="viewFlag"
              icon="el-icon-delete"
              :title="$t('operation.delete')"
              @click="handleDeleteClick(scope.$index, 3)"
            />
          </template>
        </simple-table>
      </el-tab-pane>
    </el-tabs>

    <!-- KPI Add -->
    <kpi-add
      :show-flag.sync="isShowKpiAdd"
      :tab-index="tabIndex"
      :view-flag="viewFlag"
      :row-detail="rowDetail"
      :detail-data="detailData"
      @confirm="kpiPickerConfirm"
    />
  </div>
</template>

<script>
import kpiAdd from './kpiAdd'
export default {
  components: { kpiAdd },
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    detailData: { type: Object },
    activeTab: { type: String },
    kpiData: { type: Array }
  },
  data () {
    return {
      tabIndex: 1,
      formModel: {},
      rowDetail: {},
      operation: '',
      rowIndex: 0,
      isShowKpiAdd: false,
      isShowAgencyPicker: false,
      column1: [
        { prop: 'changeRateRule', label: this.$t('config_group.fluctuationRange'), minWidth: 240 },
        { prop: 'thresholdAbsoluteValue', label: this.$t('config_group.fluctuationAbsoluteValue'), minWidth: 240 }
      ],
      column2: [
        { prop: 'thresholdRange', label: this.$t('config_group.thresholdRange'), minWidth: 240 },
        { prop: 'upperLimit', label: this.$t('config_group.upperLimit'), minWidth: 240 },
        { prop: 'lowerLimit', label: this.$t('config_group.lowerLimit'), minWidth: 240 },
        { prop: 'manualUpperLimit', label: this.$t('config_group.manualUpperLimit'), minWidth: 240 },
        { prop: 'manualLowerLimit', label: this.$t('config_group.manualLowerLimit'), minWidth: 240 }
      ],
      column3: [
        { prop: 'compareKpi.checkRule', label: this.$t('config_group.checkRule'), minWidth: 180, slotName: 'checkRule' },
        { prop: 'compareKpi.name', label: this.$t('config_group.compareName'), minWidth: 160 },
        { prop: 'compareKpi.code', label: this.$t('config_group.compareCode'), minWidth: 160 },
        { prop: 'compareKpi.businessArea', label: this.$t('config_group.compareBusinessArea'), minWidth: 160, slotName: 'businessArea' },
        { prop: 'compareKpi.calculationFormula', label: this.$t('config_group.compareCalculationFormula'), minWidth: 240 }
      ]
    }
  },
  computed: {
    tableData1 () {
      return this.kpiData.filter(data => {
        return data.type === 'mt_group_kpi_type_1'
      })
    },
    tableData2 () {
      return this.kpiData.filter(data => {
        return data.type === 'mt_group_kpi_type_2'
      })
    },
    tableData3 () {
      return this.kpiData.filter(data => {
        return data.type === 'mt_group_kpi_type_3'
      })
    },
    activeName: {
      get () {
        return this.activeTab
      },
      set (val) {
        this.$emit('update:activeTab', val)
      }
    },
    allTableData: {
      get () {
        return this.kpiData
      },
      set (val) {
        this.$emit('update:kpiData', val)
      }
    },
    formField () {
      return [
        {
          slotName: 'kpi',
          labelWidth: '0',
          col: { xs: 24, sm: 24, md: 24 },
          style: { textAlign: 'right', marginRight: '20px' }
        }
      ]
    },
    plusColumn () {
      return this['column' + this.activeTab]
    },
    type () {
      return 'mt_group_kpi_type_' + this.activeTab
    },
    tableColumn () {
      return [
        { prop: 'kpi.name', label: this.$t('config_kpi.name'), minWidth: 160 },
        { prop: 'kpi.code', label: this.$t('config_kpi.code'), minWidth: 160 },
        { prop: 'kpi.businessArea', label: this.$t('config_kpi.businessArea'), minWidth: 160, slotName: 'businessArea' },
        { prop: 'kpi.calculationFormula', label: this.$t('config_kpi.calculationFormula'), minWidth: 240 },
        ...this.plusColumn,
        {
          label: this.$t('field.operation'),
          slotName: 'operation',
          width: 80,
          fixed: 'right'
        }
      ]
    }
  },
  methods: {
    kpiPickerConfirm (data) {
      const oData = this.$_.cloneDeep(data)
      if (this.operation === 'edit') {
        this['tableData' + this.tabIndex][this.rowIndex] = oData
      } else {
        this['tableData' + this.tabIndex].push(oData)
      }
      oData.type = 'mt_group_kpi_type_' + this.tabIndex
      this.allTableData = [
        ...this.tableData1,
        ...this.tableData2,
        ...this.tableData3
      ]
    },
    handleAddClick (index) {
      this.tabIndex = index
      this.rowDetail = {}
      this.operation = 'add'
      this.isShowKpiAdd = true
    },
    handleEditClick (row, rowIndex, index) {
      this.rowIndex = rowIndex
      this.tabIndex = index
      this.rowDetail = this.$_.cloneDeep(row)
      this.operation = 'edit'
      this.isShowKpiAdd = true
    },
    handleDeleteClick (index, tabIndex) {
      this['tableData' + tabIndex].splice(index, 1)
      this.allTableData = [
        ...this.tableData1,
        ...this.tableData2,
        ...this.tableData3
      ]
    }
  }
}
</script>
